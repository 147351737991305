@import "../../../Assets/Scss/variables.scss";
@import "../../../Assets/Scss/globalStyleSheet.scss";

.overlayBackgound {
  background-image: url("../../../Assets/Images/layoutBgImage.png");
  @include bgProperties;
  height: 100vh;
  max-height: auto;

  .whatsappBtnOverlay {
    background-color: $green;
  }
}

.lightBg {
  background-color: $light-bg;
}

.transparentBg {
  background-color: transparent !important;
}
