@import "../../Assets/Scss/globalStyleSheet.scss";

.navbar {
  text-align: start;
  display: flex !important;
  align-items: center !important;
  .navLink {
    font-size: 21px;
    &:hover {
      color: $yellow !important;
    }
  }
}
.togglerNav {
  .navItem,
  a,
  div {
    padding: 6px;
    text-align: center;
    width: 100% !important;
    color: $white !important;
    font-size: 18px;
    &:hover {
      color: $yellow !important;
      font-weight: 400;
    }
  }
}
.togglerNav {
  padding: 0 0 0 0 !important;
  margin-top: -1px;
  .navItem,
  a {
    display: block;
    justify-content: center;
  }
}
.collapseScss {
  z-index: 999;
  margin-top: 57px;
  position: fixed;
}
.notificationCount {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 10px;
}
